import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '../Select';

const CurrencySwitcher = ({
  currentCurrency,
  currencies,
  onChange,
  mobile,
  label,
  help,
  className,
  basic,
  value: controlledValue,
  fluid,
}) => (
  <Select
    className={className}
    help={help}
    showLabel={mobile || basic}
    label={label}
    roundCorners={!mobile && !basic}
    tertiary={!mobile && !basic}
    basic={mobile || basic}
    value={controlledValue || currentCurrency}
    options={currencies}
    onChange={({ target: { value } }) => onChange(value)}
    fluid={fluid}
  />
);

CurrencySwitcher.propTypes = {
  currentCurrency: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  className: PropTypes.string,
  help: PropTypes.string,
  basic: PropTypes.bool,
  value: PropTypes.string,
  fluid: PropTypes.bool,
};

CurrencySwitcher.defaultProps = {
  mobile: false,
  className: undefined,
  help: undefined,
  basic: false,
  value: undefined,
  fluid: false,
};

const mapState = state => ({
  currencies: state.config.currencies,
  currentCurrency: state.intl.currency,
});

export default connect(mapState)(CurrencySwitcher);
