import React from 'react';
import HTMLReactParser, { domToReact } from 'html-react-parser';
import Link from './components/Link';

// options with callback for preprocessing the data
const options = {
  trim: true,
  replace: node => {
    try {
      const {
        type = '',
        name = '',
        attribs: { href = '', class: className = '', ...rest } = {},
        children = [],
      } = node;

      if (type === 'tag' && name === 'a') {
        // only transform if '/' at the beginning
        if (`${href}`.startsWith('/')) {
          // check if localized or not?
          if (Object.keys(node.attribs).includes('localized')) {
            // localize url
            return (
              <Link to={href} className={className} {...rest}>
                {domToReact(children, options)}
              </Link>
            );
          }
        }

        // keep the url as it is
        return (
          <a href={href} className={className} {...rest}>
            {domToReact(children, options)}
          </a>
        );
      }
    } catch (ex) {
      // do nothing
    }

    return node;
  },
};

// convert simple html string to react elements
export function convertHtmlStringToJSX(countryFooterCol) {
  // converts html string to react element
  if (typeof countryFooterCol === 'string') {
    return HTMLReactParser(countryFooterCol, options);
  }
  return null;
}

export default {
  convertHtmlStringToJSX,
};
