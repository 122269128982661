// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1ioK3{margin-right:5px;height:32px;width:46px;background:white;padding:0;border-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"root": "_1ioK3"
};
module.exports = exports;
