import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import s from './FooterBar.scss';
import Link from '../Link';
import Grid from '../Grid';
import LanguageSwitcher from '../LanguageSwitcher';
import CountrySwitcher from '../CountrySwitcher';
import List from '../List';
import CurrencySwitcher from '../CurrencySwitcher';
import GlobalizationMenu from './components/GlobalizationMenu';
import Button from '../Clickable';
import Modal from '../Modal';
import Label from '../Label';
import {
  ICONS,
  GLOBAL_GIFTCARD_PATH,
  FAQ_PATH,
  ACCOUNT_TYPE,
  REDEEM_PATH,
  SALES_CHANNELS,
  SALES_CHANNELS_GROUP,
} from '../../constants';
import { toggleMobileMenu } from '../../actions/header';
import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';
import { setPath } from '../../actions/intl';
import {
  footerMessages as messages,
  basketMessages,
} from '../../defineMessages';

const FooterBar = ({
  isMobile,
  isClient,
  countryCode,
  links,
  intl,
  mode,
  bigGlobalButton,
  salesChannelId,
  basketIsEmpty,
  salesChannelGroup,
  vouchers,
  dispatch,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [country, setCountry] = useState(countryCode);

  const changeCountry = useCallback(
    countryValue => {
      if (
        !basketIsEmpty &&
        (salesChannelId === SALES_CHANNELS.B2C ||
          salesChannelId === SALES_CHANNELS.REDEEM)
      ) {
        if (isMobile) {
          dispatch(toggleMobileMenu('globalization'));
        }
        setCountry(countryValue);
        setModalOpen(true);
      } else {
        dispatch(setPath({ country: countryValue }));
      }
    },
    [basketIsEmpty, dispatch, isMobile, salesChannelId],
  );

  const showRedeemButton =
    !isMobile &&
    !(
      bigGlobalButton ||
      salesChannelId === SALES_CHANNELS.REDEEM ||
      salesChannelGroup === SALES_CHANNELS_GROUP.PORTAL
    );

  return (
    <div className={s.root}>
      <Modal
        size="center"
        isVisible={modalOpen}
        handleClick={() => setModalOpen(!modalOpen)}
        title={intl.formatMessage({
          ...basketMessages.confirmRemovingBasketTitle,
        })}
        header
        triggerProps={{ label: null }}
        className={s.countryModal}
      >
        <div className={s.countryModal__container}>
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing="fluid"
          >
            <Grid md={17} xs="20" sm="20" item>
              <Label>
                {intl.formatMessage({
                  ...basketMessages.confirmRemovingBasket,
                })}
              </Label>
            </Grid>
            <Grid item container justify="space-evenly">
              <Grid item>
                <Button
                  label={intl.formatMessage({
                    ...basketMessages.yes,
                  })}
                  basic
                  onClick={() => {
                    if (isMobile) {
                      dispatch(toggleMobileMenu('globalization'));
                    }
                    dispatch(setPath({ country }));
                    setModalOpen(!modalOpen);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    if (isMobile) {
                      dispatch(toggleMobileMenu('globalization'));
                    }
                    setModalOpen(!modalOpen);
                  }}
                  label={intl.formatMessage({
                    ...basketMessages.cancel,
                  })}
                  primary
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
      {isClient && (
        <div className={s.container}>
          {isMobile ? (
            <List
              container
              spacing={16}
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <List component="li" item xs="auto" grow={bigGlobalButton}>
                <GlobalizationMenu
                  formatMessage={intl.formatMessage}
                  handleClick={() =>
                    dispatch(toggleMobileMenu('globalization'))
                  }
                  bigGlobalButton={bigGlobalButton}
                  changeCountry={changeCountry}
                />
              </List>
              {showRedeemButton && (
                <List component="li" item xs="auto">
                  {mode !== ACCOUNT_TYPE.B2B ? (
                    <Button
                      label={intl.formatMessage({ ...messages.redeemGiftCard })}
                      primary
                      link={<Link to={REDEEM_PATH} />}
                    />
                  ) : (
                    <Button
                      label={intl.formatMessage({ ...messages.cta })}
                      primary
                      link={<Link to={GLOBAL_GIFTCARD_PATH} />}
                    />
                  )}
                </List>
              )}
              <List component="li" item xs="auto">
                <Button
                  link={<Link to={FAQ_PATH} />}
                  icon={{
                    name: ICONS.QUESTION_MARK,
                    size: 32,
                    fill: '#919eab',
                  }}
                  tertiary
                />
              </List>
            </List>
          ) : (
            <Grid container>
              <Grid item md={4}>
                {links.pages && links.pages.length && (
                  <List
                    container
                    direction="column"
                    size="caption"
                    className={s.divider}
                  >
                    {links.pages.map(({ to, label }) => (
                      // inline styling because of classprops not adding the new rules
                      <List item component="li" style={{ zIndex: 3 }} key={to}>
                        <Link className={s.link} to={to}>
                          {intl.formatMessage({ ...messages[label] })}
                        </Link>
                      </List>
                    ))}
                    <List item component="li" style={{ zIndex: 3 }}>
                      <Button
                        className={s.link}
                        onClick={evt => {
                          evt.preventDefault();
                          if (window.Cookiebot) {
                            window.Cookiebot.renew();
                          }
                        }}
                      >
                        {intl.formatMessage({
                          ...messages.manageCookieConsent,
                        })}
                      </Button>
                    </List>
                  </List>
                )}
              </Grid>
              <Grid item xs>
                <List
                  container
                  spacing={8}
                  justify="center"
                  style={{ position: 'absolute', left: '0px', right: '0px' }}
                >
                  <List item component="li">
                    <LanguageSwitcher
                      label={intl.formatMessage({ ...messages.language })}
                      onChange={value => dispatch(setPath({ locale: value }))}
                    />
                  </List>
                  {isEmpty(vouchers) && (
                    <List item component="li">
                      <CountrySwitcher
                        label={intl.formatMessage({ ...messages.country })}
                        basic={isMobile}
                        value={countryCode}
                        onChange={value => {
                          changeCountry(value);
                        }}
                      />
                    </List>
                  )}
                  <List item component="li">
                    <CurrencySwitcher
                      label={intl.formatMessage({ ...messages.currency })}
                      onChange={value => dispatch(setPath({ currency: value }))}
                    />
                  </List>
                </List>
              </Grid>
              {/* inline styling because of classprops not adding the new rules */}
              <Grid item xs="auto" style={{ zIndex: 2 }}>
                <List container spacing={8}>
                  <List item component="li">
                    <Button
                      link={<Link to={FAQ_PATH} />}
                      tertiary
                      label={intl.formatMessage({ ...messages.help })}
                    />
                  </List>
                </List>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </div>
  );
};

FooterBar.propTypes = {
  salesChannelId: PropTypes.string.isRequired,
  salesChannelGroup: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
  isClient: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
  links: PropTypes.shape().isRequired,
  mode: PropTypes.string.isRequired,
  basketIsEmpty: PropTypes.bool.isRequired,
  bigGlobalButton: PropTypes.bool,
  vouchers: PropTypes.arrayOf(PropTypes.shape()),
  dispatch: PropTypes.func.isRequired,
};

FooterBar.defaultProps = {
  bigGlobalButton: false,
  vouchers: [],
};

const mapState = state => ({
  salesChannelId: state.config.salesChannelId,
  salesChannelGroup: state.config.salesChannel.group,
  basketIsEmpty: state.basket.data.length === 0,
  countryCode: state.intl.countryCode,
  locale: state.intl.locale,
  mode: state.user.mode,
  vouchers: state.basket.vouchers,
});

export default compose(
  withResponsiveWrapper(),
  injectIntl,
  withStyles(s),
  connect(mapState),
)(FooterBar);
