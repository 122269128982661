import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './GlobalizationMenu.scss';
import List from '../../../List';
import { ACCOUNT_TYPE } from '../../../../constants';
import Clickable from '../../../Clickable';
import LanguageSwitcher from '../../../LanguageSwitcher';
import CountrySwitcher from '../../../CountrySwitcher';
import CurrencySwitcher from '../../../CurrencySwitcher';
import { setPath } from '../../../../actions/intl';
import { footerMessages as messages } from '../../../../defineMessages';
import Flag from '../../../Flag';

// https://github.com/reactjs/react-modal/issues/632
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#app');
}

const GlobalizationMenu = ({
  isVisible,
  countryCode,
  handleClick,
  formatMessage,
  mode,
  bigGlobalButton,
  changeCountry,
  vouchers,
  dispatch,
}) => {
  return (
    <Fragment>
      <Clickable
        primary={bigGlobalButton}
        tertiary={!bigGlobalButton}
        onClick={handleClick}
        className={bigGlobalButton ? s.bigFlagButton : s.flagButton}
      >
        <Flag country={countryCode} className={s.flagIcon} />
        {bigGlobalButton && (
          <FormattedMessage
            className={s.globalLabel}
            {...messages.changeLocation}
          />
        )}
      </Clickable>
      <ReactModal
        isOpen={isVisible}
        contentLabel="Globalization"
        onRequestClose={handleClick}
        className={{
          base: cx(s.root, bigGlobalButton && s.noFooter),
          afterOpen: s.afterOpen,
          beforeClose: s.beforeClose,
        }}
        overlayClassName={s.overlay}
        bodyOpenClassName={s.open}
        htmlOpenClassName={s.open}
        closeTimeoutMS={300}
      >
        <div className={s.content}>
          <List container spacing={16} direction="column">
            <List item component="li">
              <LanguageSwitcher
                mobile
                className={s.switcher}
                label={formatMessage({ ...messages.language })}
                help={formatMessage({ ...messages.languageHelp })}
                onChange={value => dispatch(setPath({ locale: value }))}
              />
            </List>
            {vouchers?.length === 0 && (
              <List item component="li">
                <CountrySwitcher
                  className={s.switcher}
                  label={formatMessage({ ...messages.country })}
                  help={formatMessage({ ...messages.countryHelp })}
                  basic
                  value={countryCode}
                  onChange={value => changeCountry(value)}
                />
              </List>
            )}
            {mode !== ACCOUNT_TYPE.REDEEM && (
              <List item component="li">
                <CurrencySwitcher
                  mobile
                  className={s.switcher}
                  label={formatMessage({ ...messages.currency })}
                  help={formatMessage({ ...messages.currencyHelp })}
                  onChange={value => dispatch(setPath({ currency: value }))}
                />
              </List>
            )}
          </List>
        </div>
      </ReactModal>
    </Fragment>
  );
};

GlobalizationMenu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  formatMessage: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  changeCountry: PropTypes.func.isRequired,
  bigGlobalButton: PropTypes.bool,
  salesChannelId: PropTypes.string.isRequired,
  salesChannelGroup: PropTypes.string.isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.shape()),
  dispatch: PropTypes.func.isRequired,
};

GlobalizationMenu.defaultProps = {
  bigGlobalButton: false,
  vouchers: [],
};

const mapStateToProps = state => ({
  isVisible: state.header.globalization,
  countryCode: state.intl.countryCode,
  mode: state.user.mode,
  salesChannelId: state.config.salesChannelId,
  salesChannelGroup: state.config.salesChannel.group,
  vouchers: state.basket.vouchers,
});

export default compose(
  withStyles(s),
  connect(mapStateToProps),
)(GlobalizationMenu);
