import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SvgIcon.scss';

const SvgIcon = ({ name, className = s.root, fill, ...rest }) => {
  const importedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (name) {
      setLoading(true);
      const importIcon = async () => {
        try {
          importedIconRef.current = (
            await import(`@svgr/webpack!./svg/${name}.svg`)
          ).ReactComponent;
        } catch (e) {
          console.error(`Error loading svg payment icon: ${name}`);
        } finally {
          setLoading(false);
        }
      };
      importIcon();
    }
  }, [name]);

  if (!name) {
    return null;
  }

  if (!loading && importedIconRef.current) {
    const { current: ImportedIcon } = importedIconRef;
    return <ImportedIcon className={className} fill={fill} {...rest} />;
  }
  return null;
};

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  fill: PropTypes.string,
};

SvgIcon.defaultProps = {
  className: undefined,
  fill: undefined,
};

export default withStyles(s)(SvgIcon);
